import React, { Component, useState } from "react";
import { Link } from "gatsby"

import titleCase from '../../js/titleCase'
import API from '../../js/api'

import componentStyles from "./style.module.css"

const Collection = ({vectors, loading, slug, count}) => {
  let vectorScaffold = Array.apply(null, Array(8));

  return(
    <div className={componentStyles.Collection}>
      <Link to={slug ? `/${API.get("collectionUrlPath")}/${slug}/` : "#"} title={API.get("collectionLinkAction")}>
        <div className={componentStyles.CollectionImages}>
          {vectors[0] == null &&
            <div className={componentStyles.CollectionImageListing}>
              {vectorScaffold.map(((vector, i) => <div className={componentStyles.loading} key={i}></div>))}
            </div>
          }
          {vectors &&
            <div className={componentStyles.CollectionImageListing}>
              {
                vectors.map(((vector, i) =>
                  <div className={componentStyles.CollectionVector} src={vector.url} key={i}>
                    <img alt={vector.title} src={`${API.get("assetShowPrefix")}${vector.id}/60/${vector.title}.${API.get("assetShowSuffix")}`}/>
                  </div>
                ))
              }
            </div>
          }
          <div className={componentStyles.title}>{titleCase(slug ? slug.replace(/-/g, " ") : "")} {API.get("collectionPretty")} <span>{count}</span></div>
        </div>
      </Link>
    </div>
  );
}

export default Collection;
