import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../layouts/layout"
import SEO from "../components/SEO/index.js"
import Paging from "../components/Paging"
import Collection from "../components/Collection"
import Native from "../components/Native"
import BreadCrumbs from "../components/BreadCrumbs"

import API from '../js/api'

class Page extends React.Component {
  constructor(props){
    super(props);
    let type = "unset";
    let page = "unset";
    if(typeof document != "undefined"){
      let pathname = document.location.pathname;
      if(pathname){
        type = pathname.split("/")[2];
        page = pathname.split("/")[3];
        if(!page){
          page = 1;
        }
        if(!type){
          type = "all";
        }
      }
    }

    this.state = {
      page, count: 50, type,
      collections: Array.apply(null, Array(12))
    }
  }

  componentDidMount(){
    this.fetchData(this.state.type, this.state.page);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      let pathname = this.props.location.pathname;


      let type = "unset";
      let page = "unset";
      if(typeof document != "undefined"){
        let pathname = document.location.pathname;
        if(pathname){
          type = pathname.split("/")[2];
          page = pathname.split("/")[3];
          if(!page){
            page = 1;
          }
          if(!type){
            type = "all";
          }
        }
      }
      this.setState({page, type, collections: Array.apply(null, Array(12))});
      this.fetchData(type, page);
    }
  }

  fetchData(type, page){
    if(typeof window != "undefined"){
      fetch("https://api.svgrepo.com/collections/?type="+type+"&start=" + (page - 1) * 12).then(res => res.json()).then(res => {
        let collections = res.collections;
        let count = res.count;
        this.setState({collections, count})
      })
    }
  }


  render() {
    let page = this.state.page;
    let type = this.state.type;
    let typeUC = "";
    if(type == "unset"){
      type = "!#-type-#!";
      typeUC = "!#-typeUC-#!";
    }else{
      typeUC = type[0].toUpperCase() + type.substr(1);
    }

    return (
      <Layout>
        <BreadCrumbs
          links={[
            {title: "Home", link: "/"},
            {title: API.get("collectionsPretty"), link: `/${API.get("collectionsUrlPath")}/all/`},
            {title: `${typeUC} ${API.get("collectionsPretty")}`, link: `/${API.get("collectionsUrlPath")}/${type}/` }
          ]}
        />
        <h2 className="title">{API.get("collectionsTitle", [typeUC])} <span>Page {page}</span></h2>
        <p>{API.get("collectionsDescription")}</p>

        <div className="tabs">
          <div className="leftBlank"></div>
          <div className="rightBlank"></div>
          <ul className={"tabHolder"+typeUC}>
            <Link to={`/collections/all`}><li>All<span> Icons</span></li></Link>
            <Link to={`/collections/monocolor/`}><li>Monocolor</li></Link>
            <Link to={`/collections/multicolor/`}><li>Multicolor</li></Link>
            <Link to={`/collections/outlined/`}><li>Outlined</li></Link>
            <Link to={`/collections/filled/`}><li>Filled</li></Link>
          </ul>
        </div>

        <Native id={"native-js-collections-top-" + type + page}/>

        <div className="collectionListing">
          {this.state.collections.map((collection, i) => {
            return(
              <Collection key={i} vectors={collection ? collection.preview : false} slug={collection ? collection.slug : false} count={collection ? collection.count : false}/>
            )
          })}
        </div>

        <Native id={"native-js-collections-bottom-" + type + page}/>

        <Paging listing={"collections/" + this.state.type} page={this.state.page} count={this.state.count} num={12}/>
        <SEO meta={{
          title: API.get("collectionsMetaTitle", [typeUC, page]),
          description: API.get("collectionsMetaDescription", [typeUC, page]),
          slug: `https://${API.get("domain")}/${API.get("collectionsUrlPath")}/${this.state.type}/${page}`,
          breadCrumbs: [{
            to: `/${API.get("collectionsUrlPath")}/all/`,
            title: `${API.get("collectionsPretty")}`
          },{
            to: `/${API.get("collectionsUrlPath")}/${type}/`,
            title: `${typeUC} ${API.get("collectionsPretty")}`
          }]
        }} />
      </Layout>
    )
  }
}

export default Page
